.user-badge .initials {
  text-transform: uppercase;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center; }

.user-badge .profile-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  background: #D9D9C9; }
  .user-badge .profile-image img {
    margin: 0 auto;
    height: auto;
    width: 100%; }
