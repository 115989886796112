.header.LOGGED_OUT {
  height: unset !important; }

#header {
  position: fixed;
  width: 100vw;
  z-index: 9999999;
  left: 0;
  right: 0;
  height: 65px;
  background-color: #1a282c;
  display: flex;
  align-items: center; }
  #header.DONOR {
    z-index: 999999; }
  #header.APPLICANT {
    z-index: 999999; }
  #header.SCHOOLCOUNSELLOR {
    z-index: 999999; }
  #header.RECOMMENDER {
    z-index: 999999; }
  #header.logged-out {
    background-color: #1a282c;
    border-bottom: 1px solid #1a282c; }
  #header.is-mobile-applicant-logged-in {
    z-index: 999999; }

body.is-modal-visible #header {
  z-index: 999999; }

#header__inner {
  height: 100%;
  width: 100%; }

.header__menu {
  display: flex;
  justify-content: space-evenly;
  margin-left: 40px;
  cursor: pointer; }
  @media (max-width: 768.98px) {
    .header__menu {
      margin-left: 15px; } }
  @media (max-width: 479.98px) {
    .header__menu {
      margin-left: 15px; } }
  .header__menu li {
    margin-right: 32px;
    transition: color 0.25s;
    white-space: pre; }
    .header__menu li:last-child {
      margin-right: 0; }
    .header__menu li a:hover {
      color: #ffffff; }
