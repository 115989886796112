main {
  padding-top: 65px;
  min-height: calc(100vh - 65px);
  background: #F7F7F3; }
  @media (max-width: 479.98px) {
    main {
      overflow: scroll; } }

main.applicant-homepage {
  padding: 0;
  background: unset;
  min-height: unset;
  height: 100%; }
  main.applicant-homepage .applicant-home.background-color__light-grey .user-account-page {
    min-height: 100vh; }
  main.applicant-homepage#iframe-reviewer .application-review__main {
    overflow: unset; }

.notify-dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #FF542D; }

.side-drawer-menu-large-space {
  margin-left: 300px; }

.Toastify__toast--info .Toastify__toast-body {
  color: #1a282c !important;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  gap: 10px;
  display: flex;
  align-items: center; }
  .Toastify__toast--info .Toastify__toast-body .flash-icon {
    min-width: 25px;
    min-height: 25px; }
  .Toastify__toast--info .Toastify__toast-body #toast-message-body {
    max-width: 280px; }

.Toastify__toast--info .Toastify__close-button {
  color: #1a282c; }

.Toastify__toast--success .Toastify__toast-body {
  display: flex;
  align-items: center;
  gap: 10px; }
  .Toastify__toast--success .Toastify__toast-body .flash-icon {
    min-width: 25px;
    min-height: 25px; }
  .Toastify__toast--success .Toastify__toast-body #toast-message-body {
    max-width: 280px; }

.Toastify__toast--warning .Toastify__toast-body {
  color: #1a282c;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  gap: 10px;
  display: flex;
  align-items: center; }
  .Toastify__toast--warning .Toastify__toast-body .flash-icon {
    min-width: 25px;
    min-height: 25px; }
  .Toastify__toast--warning .Toastify__toast-body #toast-message-body {
    max-width: 280px; }

.Toastify__toast--warning .Toastify__close-button {
  color: #1a282c; }

.Toastify__toast--error .Toastify__toast-body {
  color: #FF542D !important;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  gap: 10px;
  display: flex;
  align-items: center; }
  .Toastify__toast--error .Toastify__toast-body .flash-icon {
    min-width: 25px;
    min-height: 25px; }
  .Toastify__toast--error .Toastify__toast-body #toast-message-body {
    max-width: 280px; }

.Toastify__toast--error .Toastify__close-button {
  color: #1a282c; }

.Toastify__close-button {
  align-self: center !important;
  color: #1a282c !important; }

.Toastify__toast {
  min-height: 30px !important;
  border-radius: 2px !important;
  background-color: #ffffff !important; }

.applicant-right-side__content-area {
  background: #f6f6f6;
  height: calc(100vh); }
  @media (max-width: 768.98px) {
    .applicant-right-side__content-area {
      margin-left: 0; } }

.left-section {
  width: 500px;
  height: 90vh;
  border: 1px solid black;
  background-color: #008067;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .left-section h1 {
    font-size: 25px;
    padding: 10px 0px 10px 0px; }
  .left-section .left-section-item {
    font-size: 25px;
    padding: 10px 0px 10px 0px; }

#donor-reviewer-page {
  padding-top: 0px !important; }

.upload_container .sweet-alert {
  padding: 2rem 1.25rem !important; }

.upload_container .upload_text {
  font-size: 24px;
  font-family: 'Inter-Regular';
  padding: 0px 0px 0px 10px; }

.upload_container .progress-uploading_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin: auto; }
  .upload_container .progress-uploading_section .progress-uploading {
    display: flex;
    border-radius: 5px;
    height: 8px;
    background-color: #d9d9d9;
    margin: 15px 0 15px 0;
    position: relative;
    width: 100%; }
  .upload_container .progress-uploading_section .progress-uploading_percent {
    padding: 0px 5px;
    color: #696969; }
  .upload_container .progress-uploading_section .progress-uploading_bar {
    background-color: #1C21DF;
    margin: 0px 0 15px 0;
    height: 8px;
    border-radius: 5px;
    position: absolute;
    top: 0; }

.applicant-application-form-container {
  overflow: hidden; }

#applicant-application-form-container {
  overflow: hidden;
  padding: 0px; }
